<template>
  <div
    class="
      transform-gpu
      transition-all
      duration-1000
      ease-out
      items-stretch
      justify-center
      h-screen
      max-w-100vw
      w-full
      overflow-hidden
      flex flex-col flex-1
      bg-white
    "
  >
    <div
      class="
        flex flex-col
        items-stretch
        text-left
        pl-8
        sm:pl-12
        md:pl-24
        xl:pl-32
        pt-20
        lg:pt-24
        flex-1
        relative
      "
    >
      <transition
        enter-active-class="tranform transition-all duration-500 ease-in"
        leave-active-class="tranform transition-all duration-500 ease-in"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <HeaderComponent v-if="loadHeading" :darkTheme="true"></HeaderComponent>
      </transition>

      <transition
        enter-active-class="transform-gpu transition-all duration-500 ease-in-out"
        leave-active-class="transform-gpu transition-all duration-500 ease-in-out"
        enter-class="-ml-12 opacity-0"
        enter-to-class="-ml-0 opacity-100"
        leave-class="-ml-0 opacity-100"
        leave-to-class="-ml-12 opacity-0"
      >
        <h1
          v-show="loadContent"
          class="text-2xl max-w-3/4 md:text-4xl ml-1 font-headline"
        >
          Cookies 4 Later
        </h1>
      </transition>
      <transition
        enter-active-class="transform transition-all duration-500 ease-in-out"
        leave-active-class="transform transition-all duration-500 ease-in-out"
        enter-class="-ml-14 opacity-0"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-to-class="-ml-14 opacity-0"
      >
        <div v-show="loadContent" 
          class="lg:pr-60"
          style="padding-bottom: 20px; max-height: 70vh; overflow: auto;"
          >
          <div
            class="
              font-primary font-medium
              max-w-3/4
              lg:max-w-screen-lg
              text-md
              md:text-md
              leading-relaxed
              mt-5
              md:mt-6
              xl:ml-2"
          >
            <div class="flex-1 flex justify-between flex-col text-md">
              <p class="mb-3">Welcome to my little corner of the internet! I am a developer and gamer from the vibrant and diverse landscapes of South Africa.</p>
              <p class="mb-3">A passionate developer and designer specializing in app development and game art. With a dedication to creativity and a focus on user experience, I transform ideas into engaging digital experiences.</p>
              <p>Explore my work and see how I bring visions to life through innovative design and development.</p>
              
              <br/> 
              
              <section class="my-2">
                <h5 class="uppercase text-lg font-bold mb-3 underline">Gaming Art</h5>
                <p>Discover the art of virtual photography through the immersive world of video games.</p>
                <p>From breathtaking landscapes to intricate character portraits, this collection highlights the diverse beauty found in virtual worlds.</p>
                <div class="mt-3 flex gap-4 align-center justify-start">
                  <router-link to="gaming-art" class="cursor-pointer w-auto text-sm md:text-base underline">View Gallery</router-link>
                </div>
              </section>

              <br/> 

              <section class="my-2">
                <h5 class="uppercase radius-2 text-lg font-bold mb-3 underline">Projects</h5>
                <p class="mb-2">
                  <router-link to="kings" ><img class="h-auto w-full sm:h-8 sm:w-8 mr-2 block sm:inline" src="/img/kings-icon.png"/><span class="inline cursor-pointer w-auto text-sm md:text-base underline"><strong>Kings</strong></span></router-link>: A virtual card game where the user performs actions based on the card drawn from the circle. 
                </p>
              </section>
            </div>
          </div>
        </div>
      </transition>

      <router-link
        to="kings"
        class="
          h-screen
          fixed
          right-0
          top-0
          w-8
          md:w-12
          bg-darkblue
          text-white
          hover:bg-black
          transition transition-bg
          flex
          items-center
          justify-center
          z-10
        "
      >
        <div class="relative h-screen w-8 flex items-center justify-center">
          <span
            class="
              absolute
              uppercase
              w-100vh
              h-8
              flex
              text-sm
              md:text-base
              items-center
              justify-center
              transform
              rotate-90
              tracking-wide
            "
            >Kings - drinking game</span
          ><i class="icon-arrow-down"></i>
        </div>
      </router-link>

      <router-link
        to="gaming-art"
        class="
          h-screen
          absolute
          right-8
          md:right-12
          top-0
          w-8
          md:w-12
          bg-darkblue
          text-white
          hover:bg-black
          transition transition-bg
          flex
          items-center
          justify-center
          z-10
        "
      >
        <div class="relative h-screen w-8 flex items-center justify-center">
          <span
            class="
              absolute
              uppercase
              w-100vh
              h-8
              flex
              items-center
              justify-center
              transform
              rotate-90
              tracking-wide
              text-sm
              md:text-base
            "
            >Gaming Art</span
          ><i class="icon-arrow-down"></i>
        </div>
      </router-link>
    </div>

    <cookie-consent class="mb-8 ml-8 md:ml-0 md:mb-0 p-3 md:p-0 h-auto max-w-2/3 md:max-w-full md:w-full"> 
      <template slot="message" class="">
        This website uses cookies to ensure you get the best experience.
        <a class="underline ml-2" href="/terms">Read more</a>
      </template>
      <template  slot="button">
        <button class="py-2 px-5 min-w-32 ml-4 bg-darkblue text-white ">Accept</button>
    </template>
  </cookie-consent>

  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import CookieConsent from 'vue-cookieconsent-component'

export default {
  name: "Home",
  components: {
    HeaderComponent,
    CookieConsent
  },
  data() {
    return {
      loadContent: Boolean,
      loadHeading: Boolean,
    };
  },
  mounted() {
    this.loadContent = false;
    this.loadHeading = false;
    setTimeout(() => {
      this.loadContent = true;
    }, 1500);
    setTimeout(() => {
      this.loadHeading = true;
    }, 300);
  },
  beforeDestroy() {
    this.loadContent = false;
    this.loadHeading = false;
  },
};
</script>
